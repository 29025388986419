<template>
  <div id="not-found-info">
    <h6>Sorry, there are no {{ contentType }} available yet for this project. Please contact the project sharer.</h6>
    <b-button variant="primary">
      <a id="support-link" href="mailto:support@orbitalstack.com">
        Contact Support
      </a>
    </b-button>
  </div>
</template>

<script>

export default {
  name: 'ContentNotFoundPage',
  props: {
    contentType: {
      required: true,
      type: String
    }
  }
};
</script>

<style scoped>
#not-found-info {
  left: calc(50% - 10px);
  bottom: calc(50% - 18.8px);
  width: 450px;
  position: absolute;
  text-align: center;
}

#support-link {
  text-decoration: none;
  color: white;
  font-weight: 900;
}
</style>
